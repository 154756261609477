import React from "react";
import styles from "./PrivacyPolicy.module.css";
import { Typography, Box, Grid, Paper } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className={styles.main}>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <Helmet>
        <title>Privacy Policy - PK Paniwala</title>
        <link rel="canonical" href="https://www.pkpaniwala.com/privacy-policy" />
      </Helmet>
      <Box className={styles.container} component={Paper}>
        <Typography className={styles.title} variant="h4">
          Privacy Policy for PK Paniwala Water Delivery Service
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", marginBottom: "20px", fontStyle: "italic" }}
        >
          Effective Date: 1st January 2025
        </Typography>
        <Typography paragraph className={styles.description}>
          At PK Paniwala, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal and sensitive information when you use our app and services. By using the PK Paniwala app, you agree to the practices described in this policy.
        </Typography>

        <Box className={styles.section}>
          <Typography variant="h6">1. Information We Collect</Typography>
          <Typography paragraph>
            <strong>a. Personal Information</strong> <br />
            • Name, address, phone number, and email address for registration and order fulfillment. <br />
            • Payment information (e.g., UPI ID or other payment details) to process transactions.
          </Typography>
          <Typography paragraph>
            <strong>b. Sensitive User Data</strong> <br />
            • Location Data: We collect and access your device’s location, including background location, to assign the nearest driver, provide real-time delivery tracking, and ensure accurate delivery services. 
          </Typography>
          <Typography paragraph>
            <strong>c. Non-Personal Information</strong> <br />
            • Device information (e.g., model, operating system), IP address, and app usage data to enhance service functionality and improve user experience. 
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">2. How We Use Your Information</Typography>
          <Typography paragraph>
            • To process and deliver your water orders. <br />
            • To track deliveries in real time using background location data. <br />
            • To send you service-related communications, including updates, promotional offers, and customer support. <br />
            • To improve the functionality of our app and services. <br />
            • To comply with legal requirements or protect our rights and the rights of others.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">3. Sharing Your Information</Typography>
          <Typography paragraph>
            We do not sell or rent your data. We may share your data in the following situations: <br />
            • Service Providers: We share information with trusted third-party vendors to process payments, deliver water orders, and provide technical support. These third parties are contractually obligated to protect your data. <br />
            • Legal Compliance: We may share your data when required by law or to protect the rights, property, or safety of our company or users. <br />
            • Business Transfers: In the event of a merger, acquisition, or transfer of assets, your data may be transferred to the new entity.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">4. Data Security</Typography>
          <Typography paragraph>
            We take the following steps to secure your personal and sensitive information: <br />
            • Encryption of sensitive data, such as payment information. <br />
            • Secure storage of location data and user details. <br />
            • Regular security audits and access control measures to prevent unauthorized access.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">5. Data Retention and Deletion Policy</Typography>
          <Typography paragraph>
            • Data Retention: We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. <br />
            • Data Deletion: You may request to delete your personal information by contacting us at support@pkpaniwala.com. We will delete your data as per applicable laws and regulations.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">6. Your Rights</Typography>
          <Typography paragraph>
            You have the right to: <br />
            • Access the personal information we hold about you. <br />
            • Request corrections to any inaccuracies in your personal information. <br />
            • Request deletion of your personal information. <br />
            To exercise any of these rights, please contact us at support@pkpaniwala.com.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">7. Location Data Disclosure</Typography>
          <Typography paragraph>
            Our app requires access to background location data to provide real-time delivery tracking and assign the nearest available driver to your delivery request. The use of background location is essential for accurate delivery and a smooth customer experience.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">8. Changes to This Privacy Policy</Typography>
          <Typography paragraph>
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and the effective date will be revised accordingly.
          </Typography>
        </Box>

        <Box className={styles.section}>
          <Typography variant="h6">9. Contact Information</Typography>
          <Grid container spacing={2} className={styles.contact}>
            <Grid item xs={12} sm={6} md={12}>
              <PhoneIcon className={styles.icon} />
              <span className={styles.highlight}>Phone:</span> 8789535136
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <EmailIcon className={styles.icon} />
              <span className={styles.highlight}>Email:</span> help@pkpaniwala.com
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <LocationOnIcon className={styles.icon} />
              <span className={styles.highlight}>Address:</span> Ground Floor & 1st Floor, House No. 567, Gate No. 3, Service Lane, HUDA, Sector 23, Gurugram, Haryana 122017
            </Grid>
          </Grid>
        </Box>
      </Box>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;